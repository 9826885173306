import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import moment from 'moment';

import Layout from '../components/Layout';
import SEO from '../components/seo';

class Photography extends React.Component {
    render() {
        const images = this.props.data.allFile.edges;

        return (
            <Layout>
                <SEO htmlClass="photolog" title="Photography" keywords={[`photography`]} />
                <header>
                    <h1>Photography</h1>
                    <p>
                        By <Link to="/">Rowan</Link>
                    </p>
                </header>
                {images.map((obj, idx) => {
                    const img = obj.node;
                    const parts = img.base.split('__');
                    const imgTs = moment.unix(parts[0]).format("D M 'YY");
                    const imgTitle = parts[1].split('.jpg')[0];
                    const imgCaption = parts.length > 2 ? parts[2].split('.jpg')[0] : '';
                    const imgDate = moment.unix(parts[0]).format('MMMM D, YYYY');

                    const captionFg = img.fields.offsetColor;
                    const captionBg = img.fields.averageColor;

                    return (
                        <article className="img-wrap" key={img.id}>
                            <Link to={`/photography/${parts[0]}`}>
                                <Img
                                    title={imgCaption ? imgCaption : imgTitle}
                                    fluid={img.childImageSharp.fluid}
                                />
                            </Link>
                            <span className="img-ts">{imgTs}</span>
                            <div className="img-small-details">
                                <p className="img-small-num">
                                    {images.length - idx}
                                    <span
                                        className="img-small-ball-bg"
                                        style={{
                                            background: img.fields.isDark ? captionBg : captionFg,
                                        }}
                                    ></span>
                                    <span
                                        className="img-small-ball-fg"
                                        style={{
                                            background: img.fields.isDark ? captionFg : captionBg,
                                        }}
                                    ></span>
                                </p>
                                <p
                                    className={`img-small-caption ${
                                        imgCaption ? 'has-caption' : ''
                                    }`}
                                    style={{
                                        background: captionBg,
                                    }}
                                >
                                    {imgCaption}
                                </p>
                                <p className="img-small-date">{imgDate}</p>
                            </div>
                        </article>
                    );
                })}
                <article>
                    <p>
                        <Link to="/">
                            <span className="arrow">←</span>&nbsp;&nbsp;Back to the rest of the
                            website.
                        </Link>
                    </p>
                </article>
            </Layout>
        );
    }
}

export default Photography;

export const pageQuery = graphql`
    query {
        allFile(
            filter: { dir: { regex: "src/photography/" } }
            sort: { fields: base, order: DESC }
        ) {
            edges {
                node {
                    id
                    base
                    fields {
                        averageColor
                        offsetColor
                        isDark
                    }
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
